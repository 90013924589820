.App {
	text-align: center;
}

.invisible {
	display: none;
}

.footer {
	font: .7rem "Neue Helvetica W05", Helvetica, Arial, sans-serif;
	margin: 2rem;
}

#logoWrapper {
	margin: 2rem auto;
	width: 20rem;
	text-align: center;
}

#logo {
	margin-bottom: 0;
	text-align: left;
	line-height: 2rem;
	font-size: 2.5rem;
	font-weight: bold;
}

#tagline {
	text-align: left;
	font-size: 1rem;
	font-weight: normal;
}

.App h1 {
	margin-bottom: 0;
	font-weight: normal;
}

#fretboardWrapper {
	text-align: center;
	margin-bottom: 4rem;
}

#fretboardContainer {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}

#caption {
	margin-bottom: 2rem;
}

#lengthSelector {
	text-align: center;
}

#lengthSelector #fblLabel {
	margin-left: 1rem;
	margin-bottom: 2rem;
}

#shiftSelectorSection {
	margin-top: 1rem;
}

#parallelOption {
	margin-left: 1rem;
}


#lengthSelectorSection, #scaleTypeSelectorSection, #densitySelectorSection, #labelSelectorSection #shiftSelectorSection {
	margin: 1rem 0;
}

